 
$(document).ready(function(){
	$(".header__menu").on("click","a", function (event) {
		//отменяем стандартную обработку нажатия по ссылке
		event.preventDefault();

		//забираем идентификатор бока с атрибута href
		var id  = $(this).attr('href'),

		//узнаем высоту от начала страницы до блока на который ссылается якорь
			top = $(id).offset().top;
		
		//анимируем переход на расстояние - top за 1500 мс
		$('body,html').animate({scrollTop: top}, 500);
	});

  $(".footer__menu").on("click","a", function (event) {
		//отменяем стандартную обработку нажатия по ссылке
		event.preventDefault();

		//забираем идентификатор бока с атрибута href
		var id  = $(this).attr('href'),

		//узнаем высоту от начала страницы до блока на который ссылается якорь
			top = $(id).offset().top;
		
		//анимируем переход на расстояние - top за 1500 мс
		$('body,html').animate({scrollTop: top}, 1500);
	});

	// function openMenu(){
	// 	if($('.header__menu').css('display') == 'none'){
	// 		$('.header__menu').fadeIn(300);
	// 	}else{
	// 		$('.header__menu').fadeOut(300);
	// 	}
	// }

	$('.burger').on('click', function(){
		$(this).toggleClass('burger--active');
		// openMenu();
	})


	$('[href="#openmodal"]').on('click', function(){
		$('.popup__form-wrapper').fadeIn(500);
	})

	$('[href="#popupclose"]').on('click', function(){
		$(this).closest('.popup__form-wrapper').fadeOut(500);
	})

	$('.popup__form-wrapper form').on('submit', function(){
		$(this).closest('.popup__form-wrapper').fadeOut(500);
	})
	new WOW().init();
  
});